@import "../vars";

@mixin custom-carousel {
  width: 800px;

  @media screen and (max-width: 920px) {
    width: 500px;
  }

  @media screen and (max-width: 520px) {
    width: 300px;
  }
}
