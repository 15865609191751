// colors
$background-color: #f2f5f7;
$button-color: #583bb6;
$text-color: #191D26;
$table-border-color: #f1f1f1;
$light-purple: #80489C;
$light-green: #68B984;
$grey-mui-hover: rgba(25, 118, 210, 0.04);

// other
$block-height: 435px;

// font
$font-family: Gill Sans;
$font-main: 400 14px/21px $font-family;

// animations
$bounce-in-top: 1.1s both;
