.Home {
  width: 100%;
}
.Home .navigation-bar {
  position: fixed;
  width: 100%;
  padding: 8px 5%;
  background: white;
  z-index: 10;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.27);
}
.Home .navigation-bar .MuiButtonBase-root {
  color: #583bb6;
  font-weight: 600;
  margin-right: 10px;
}
.Home .navigation-bar-menu {
  display: inline;
  width: inherit;
}
.Home .navigation-bar-menu-items {
  display: flex;
  justify-content: space-between;
}
.Home .navigation-bar-menu-wrapper {
  display: none;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.Home .navigation-bar-menu-wrapper-localization-btn {
  display: none;
  position: relative;
  color: rgba(0, 0, 0, 0.87) !important;
}
.Home .navigation-bar-menu-wrapper-localization-btn svg path {
  fill: #583bb6;
}
.Home .navigation-bar-menu-wrapper-localization-btn:hover {
  color: #583bb6 !important;
}
.Home .navigation-bar-menu-burger {
  display: none;
  position: relative;
}
.Home .block {
  min-height: 435px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 21px/36px Gill Sans;
  padding: 50px 20px;
  color: #191D26;
}
.Home .block.header {
  padding-top: 70px;
  flex-direction: row-reverse;
}
.Home .block--social-media {
  margin-top: 100px;
}
.Home .block--social-media .MuiButtonBase-root .social-link {
  display: flex;
  justify-content: space-between;
  width: 100px;
  color: #583bb6;
  text-decoration: none;
  outline: none;
  font-weight: 700;
}
.Home .block--social-media .MuiButtonBase-root .social-link:visited {
  color: #583bb6;
}
.Home .block--img {
  border-radius: 45px;
  width: 500px;
}
.Home .block .sub-block .custom-carousel {
  width: 800px;
}
@media screen and (max-width: 920px) {
  .Home .block .sub-block .custom-carousel {
    width: 500px;
  }
}
@media screen and (max-width: 520px) {
  .Home .block .sub-block .custom-carousel {
    width: 300px;
  }
}
.Home .block .sub-block h5 {
  margin-bottom: 25px;
}
.Home .block .sub-block ul {
  width: 700px;
}
.Home .block .sub-block table {
  width: 100%;
}
.Home .block .sub-block table tbody tr {
  display: flex;
  justify-content: space-between;
  width: 800px;
  padding: 8px 0;
}
.Home .block .sub-block table tbody tr:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}
.Home .block .sub-block table tbody tr td:first-child {
  color: #80489C;
}
.Home .block .sub-block table tbody tr td:last-child {
  color: #68B984;
}
.Home .block .sub-block h1 {
  margin: 25px;
}
.Home .block .sub-block h1.uppercase {
  font-size: 22px;
}
.Home .block .sub-block h1.animated {
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
}
.Home .block:nth-child(2n+1) {
  background: #f2f5f7;
}

.MuiList-root {
  width: 170px;
}
.MuiList-root .MuiMenuItem-root {
  font-weight: 600;
}
.MuiList-root .MuiMenuItem-root svg path {
  fill: #583bb6;
}
.MuiList-root .MuiMenuItem-root:hover {
  color: #583bb6;
}

.MuiBackdrop-invisible {
  background: rgba(0, 0, 0, 0.25) !important;
}

@media screen and (max-width: 1080px) {
  .Home .block.header {
    flex-direction: column;
  }
}
@media screen and (max-width: 940px) {
  .Home .block .sub-block ul {
    width: 500px;
  }
  .Home .block .sub-block table tbody tr {
    width: 600px;
  }
}
@media screen and (max-width: 770px) {
  .Home .navigation-bar-menu {
    display: none;
  }
  .Home .navigation-bar-menu-wrapper {
    display: flex;
  }
  .Home .navigation-bar-menu-wrapper-localization-btn {
    display: inherit;
  }
  .Home .navigation-bar-menu-burger {
    display: block;
  }
  .Home .block .sub-block table tbody tr {
    width: 540px;
  }
}
@media screen and (max-width: 605px) {
  .Home .block--img {
    width: 420px;
  }
  .Home .block .sub-block ul {
    width: 400px;
    margin: auto;
  }
  .Home .block .sub-block table tbody tr {
    width: 420px;
  }
  .Home .block .sub-block table tbody tr td {
    display: flex;
    align-items: center;
    height: 100px;
  }
  .Home .block .sub-block table tbody tr td:first-child {
    text-align: left;
  }
  .Home .block .sub-block table tbody tr td:last-child {
    text-align: right;
  }
}
@media screen and (max-width: 540px) {
  .Home .navigation-bar {
    left: 0;
    width: 100%;
  }
  .Home .block .sub-block ul {
    width: 350px;
  }
}
@media screen and (max-width: 450px) {
  .Home .block--img {
    width: 300px;
  }
  .Home .block .sub-block ul {
    width: 300px;
  }
  .Home .block .sub-block table tbody tr {
    width: 300px;
  }
}
@-webkit-keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

