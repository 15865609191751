.App {
  text-align: center;
  overflow-x: hidden;
}
.App .language-overlay-section {
  display: block;
  position: fixed;
  top: 45%;
  bottom: 45%;
  width: 64px;
  height: 100px;
  z-index: 10;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.App .language-overlay-section > span {
  margin: 14px 15px;
  padding: 5px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  border-radius: 50%;
}
.App .language-overlay-section > span:not(.selected):hover {
  background-color: rgba(25, 118, 210, 0.04);
  color: #583bb6;
}
.App .language-overlay-section > span.selected {
  background: #583bb6;
  color: white;
}
.App ul {
  text-align: left;
}
.App .uppercase {
  text-transform: uppercase;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gill Sans", sans-serif;
}

@media screen and (max-width: 770px) {
  .App .language-overlay-section {
    display: none;
  }
}
@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

