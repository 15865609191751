@import "../../../../src/assets/styles/vars";

.CarouselItem {
  height: 100%;

  .paper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90%;
  }
}
