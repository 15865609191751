@import "../../../../src/assets/styles/vars";
@import "../../../../src/assets/styles/mixins/carousel";

.Home {
  width: 100%;

  .navigation-bar {
    position: fixed;
    width: 100%;
    padding: 8px 5%;
    background: white;
    z-index: 10;
    box-shadow: 0 5px 5px -1px rgba(0,0,0,0.27);

    .MuiButtonBase-root {
      color: $button-color;
      font-weight: 600;
      margin-right: 10px;
    }

    &-menu {
      display: inline;
      width: inherit;

      &-items {
        display: flex;
        justify-content: space-between;
      }

      &-wrapper {
        display: none;
        justify-content: space-between;
        width: 100%;
        position: relative;

        &-localization-btn {
          display: none;
          position: relative;
          color: rgba(0, 0, 0, 0.87) !important;

          svg path {
            fill: $button-color;
          }

          &:hover {
            color: $button-color !important;
          }
        }
      }

      &-burger {
        display: none;
        position: relative;
      }
    }
  }

  .block {
    min-height: $block-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 400 21px/36px $font-family;
    padding: 50px 20px;
    color: $text-color;

    &.header {
      padding-top: 70px;
      flex-direction: row-reverse;
    }

    &--social-media {
      margin-top: 100px;

      .MuiButtonBase-root { // button
        .social-link {
          display: flex;
          justify-content: space-between;
          width: 100px;
          color: $button-color;
          text-decoration: none;
          outline: none;
          font-weight: 700;

          &:visited {
            color: $button-color;
          }
        }
      }
    }

    &--img {
      border-radius: 45px;
      width: 500px;
    }

    .sub-block {
      .custom-carousel {
        @include custom-carousel;
      }

      h5 {
        margin-bottom: 25px;
      }

      ul {
        width: 700px;
      }

      table {
        width: 100%;

        tbody {
          tr {
            display: flex;
            justify-content: space-between;
            width: 800px;
            padding: 8px 0;

            &:not(:last-child) {
              border-bottom: 1px solid $table-border-color;
            }

            td {
              &:first-child {
                color: $light-purple;
              }
              &:last-child {
                color: $light-green;
              }
            }
          }
        }
      }

      h1 {
        margin: 25px;

        &.uppercase {
          font-size: 22px;
        }

        &.animated {
          -webkit-animation: bounce-in-top $bounce-in-top;
          animation: bounce-in-top $bounce-in-top;
        }
      }
    }

    &:nth-child(2n+1) {
      background: $background-color;
    }
  }
}

// burger menu list

.MuiList-root {
  width: 170px;

  .MuiMenuItem-root {
    font-weight: 600;

    svg path {
      fill: $button-color;
    }

    &:hover {
      color: $button-color;
    }
  }
}

.MuiBackdrop-invisible {
  background: rgba(0, 0, 0, 0.25) !important;
}

// media

@media screen and (max-width: 1080px) {
  .Home {
    .block {
      &.header {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .Home {
    .block {
      .sub-block {
        ul {
          width: 500px;
        }

        table {
          tbody {
            tr {
              width: 600px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .Home {
    .navigation-bar {
      &-menu {
        display: none;

        &-wrapper {
          display: flex;

          &-localization-btn {
            display: inherit;
          }
        }

        &-burger {
          display: block;
        }
      }
    }

    .block {
      .sub-block {
        table {
          tbody {
            tr {
              width: 540px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 605px) {
  .Home {
    .block {
      &--img {
        width: 420px;
      }

      .sub-block {
        ul {
          width: 400px;
          margin: auto;
        }

        table {
          tbody {
            tr {
              width: 420px;

              td {
                display: flex;
                align-items: center;
                height: 100px;

                &:first-child {
                  text-align: left;
                }
                &:last-child {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .Home {
    .navigation-bar {
      left: 0;
      width: 100%;
    }

    .block {
      .sub-block {
        ul {
          width: 350px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .Home {
    .block {
      &--img {
        width: 300px;
      }

      .sub-block {
        ul {
          width: 300px;
        }

        table {
          tbody {
            tr {
              width: 300px;
            }
          }
        }
      }
    }
  }
}

// animations

@-webkit-keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
