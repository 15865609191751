@import "../src/assets/styles/vars";

.App {
  text-align: center;
  overflow-x: hidden;

  .language-overlay-section {
    display: block;
    position: fixed;
    top: 45%;
    bottom: 45%;
    width: 64px;
    height: 100px;
    z-index: 10;

    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    > span {
      margin: 14px 15px;
      padding: 5px;
      display: block;
      cursor: pointer;
      font-weight: bold;
      border-radius: 50%;

      &:not(.selected):hover {
        background-color: $grey-mui-hover;
        color: $button-color;
      }

      &.selected {
        background: $button-color;
        color: white;
      }
    }
  }

  ul {
    text-align: left;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gill Sans', sans-serif;
}

@media screen and (max-width: 770px) {
  .App {
    .language-overlay-section {
      display: none;
    }
  }
}

// animations

@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
